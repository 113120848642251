import { useEffect, useState, useRef } from 'react';

const useScroll = ({ data, isLoading, enableScrolling = false}) => {
	const [ scroll, setScroll ] = useState(false);
	const scrollRef = useRef();
	const waiting = useRef(true);
	const timeout = useRef(null);

	useEffect(() => {
		timeout.current = setTimeout(() => waiting.current = false, 3000)
		return (() => clearTimeout(timeout.current))
	})

	// Scroll to the bottom of the message list.
	useEffect(() => {
		if (scrollRef.current) {
			if (scrollRef.current.scrollHeight > scrollRef.current.clientHeight) {
				setScroll(true)
			} else {
				setScroll(false)
			}
			if (enableScrolling && !waiting.current) {
				if (enableScrolling === 'down') {
					scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
				} else if (enableScrolling === 'up') {
					scrollRef.current.scrollTop = 0;
				}
			}
		}
	}, [data, isLoading, scrollRef, enableScrolling, setScroll])

	return { scroll, setScroll, scrollRef }
}

export default useScroll;
