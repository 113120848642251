import TYPES from './types.js';
import api from '../util/api';
import socket from '../util/socket';

class StoreService {
	constructor() {
		this.state = null;
		this.dispatch = null;
	}

	updateState = (state, dispatch) => {
		this.state = state;
		this.dispatch = dispatch;
	}

	loadAuthorization = (token) => {
		if (token) {
			localStorage.setItem('v2authToken', token);
		} else {
			token = localStorage.getItem('v2authToken');
		}

		if (token) {
			const authString = `User ${token}`
			api.defaults.headers['Authorization'] = authString;
			socket.authToken = token;

			this.dispatch({
				type: TYPES.SET_AUTHORIZED,
				data: true
			});
		} else {
			this.dispatch({
				type: TYPES.SET_AUTHORIZED,
				data: false
			});
		}

		socket.connect();
	}

	clearAuthorization = () => {
		localStorage.removeItem('v2authToken');
		api.defaults.headers['Authorization'] = '';
		delete socket.authToken;
		socket.disconnect();

		this.dispatch({
			type: TYPES.SET_AUTHORIZED,
			data: false
		});
		this.dispatch({
			type: TYPES.SET_ME,
			data: null
		});
	}

	fetchMe = async () => {
		if (!this.state.authorized) {
			return;
		}
		try {
			const { data } = await api.get('/users/me');
			this.dispatch({
				type: TYPES.SET_ME,
				data
			});
		} catch (err) {
			console.error(err);
			if (err.response && err.response.status === 401) {
				this.clearAuthorization();
			} else {
				this.dispatch({
					type: TYPES.SET_AUTHORIZED,
					data: false
				});
			}
		}
	}

	fetchServers = async () => {
		try {
			const { data: servers } = await api.get(`/servers?orgId=${this.state.orgId}`);

			let playerCount = 0;
			for (const [index, server] of servers.entries()) {
				if (server.name.includes("Creative")) {
					servers[index].creative = true;
				}
				const pop = server.population
				playerCount += pop.players + pop.joining + pop.queued;
			}

			this.dispatch({
				type: TYPES.SET_SERVERS,
				data: { servers, playerCount }
			});
		} catch (err) {
			console.error(err);
			this.dispatch({
				type: TYPES.SET_SERVERS,
				data: { error: 'Failed to load servers.' }
			});
		}
	}

	setPopup = (popup = null) => {
		this.dispatch({
			type: TYPES.SET_POPUP,
			data: popup
		});
	}

	addNotification = (notif) => {
		const notifications = new Set(this.state.notifications)
		const notification = { ...notif, id: new Date().getTime() }
		notifications.add(notification);
		this.dispatch({
			type: TYPES.SET_NOTIFICATIONS,
			data: Array.from(notifications)
		});
		setTimeout(() => {
			this.removeNotification(notification);
		}, notif.duration ? notif.duration * 1000 : 6000)
	}

	removeNotification = (notification) => {
		const notifications = new Set(this.state.notifications)
		if (notification) {
			notifications.delete(notification);
		}
		this.dispatch({
			type: TYPES.SET_NOTIFICATIONS,
			data: Array.from(notifications)
		});
	}
}

export default StoreService;
