import React, { createContext, useReducer, useContext } from 'react';
import reducer from './reducer.js';
import StoreService from './service.js';

const initialState = {
	authorized: null,
	me: null,
	servers: [],
	totalNetworkPlayers: 0,
	serversError: null,
	orgId: null,
	popup: null,
	notifications: []
};

const StoreContext = createContext();

export const StoreProvider = ({ orgId, children }) => {
	initialState.orgId = orgId;

	return (
		<StoreContext.Provider value={useReducer(reducer, initialState)}>
			{children}
		</StoreContext.Provider>
	)
};

const storeService = new StoreService();
export function useStoreService() {
	const [state, dispatch] = useContext(StoreContext);
	storeService.updateState(state, dispatch);
	return storeService
}
