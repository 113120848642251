import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const StatusCircle = styled.span` 
	width: 0.9rem;
	height: 0.9rem;
	background-color: ${props => props.active ? props.theme.colors.positive : props.theme.colors.subtext};
	border-radius: 50%;
	${props => props.absolute && `
	position: absolute;
	width: 1.2rem;
	height: 1.2rem;
	bottom: -0.275rem;
	right: -0.275rem;
	border: solid 0.2rem ${props.theme.colors.backgrounds[1]};
	`}
`;

const RustySessionsStatusStyle = styled.span` 
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
	font-size: 1.15rem;
	gap: 0.5rem;
	margin-left: ${props => props.marginLeft ? 'auto' : 'inherit'};
	user-select: none;
`;

function RustySessionsStatus({ marginLeft, noLabel, session, absolute }) {
	let statusText = 'No session history';

	if (session && session.active) {
		statusText = `Online ${session.serverName} since ${moment(session.startedAt).fromNow()}`;
	} else if (session && !session.active) {
		statusText = `Last seen on ${session.serverName} ${moment(session.endedAt).fromNow()}`;
	}

	return (
		<RustySessionsStatusStyle marginLeft={marginLeft} title={statusText}>
			<StatusCircle absolute={absolute} active={session && session.active} />
			{!noLabel && ((session && session.serverName) || 'Unknown')}
		</RustySessionsStatusStyle>
	);
}


export default RustySessionsStatus;
