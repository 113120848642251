import React from 'react'
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import "react-datepicker/dist/react-datepicker.css";

import { Span } from './RustyStyles.jsx';

const RustyDatePicker = ({ dateFilter, params, setParams, alt, dark, duration }) => {
	const presets = duration ? durationPresets : defaultPresets;
	const [ label, setLabel ] = React.useState(((params && (params[dateFilter.after] || params[dateFilter.before])) && 'Custom') || presets[0].title);
	const [ showDatePicker, setShowDatePicker ] = React.useState(false);
	const [ showCustomPicker, setShowCustomPicker ] = React.useState(false);
	
	return (
		<DatePickerDiv $dark={dark}>
			<div>
				<Span bold inherit>{dateFilter.title}</Span>: {label}
				<DateSelector $dark={dark} title={`${showDatePicker ? 'Hide' : 'Show'} datepicker`} $showing={showDatePicker} icon={faClock} onMouseDown={() => setShowDatePicker(oldBool => !oldBool)} />
			</div>
			{ showDatePicker && 
				<DateRange custom={showCustomPicker} $alt={alt} >
					<div>
						<div onClick={() => setShowCustomPicker(false)}>Presets</div>
						<div onClick={() => setShowCustomPicker(true)}>Custom</div>
					</div>
					{ showCustomPicker ? 
						<>
							{ !duration && <>After <DatePicker placeholderText="Click to select a date" dateFormat="MM/dd/yyyy h:mm aa" showTimeInput isClearable selectsStart startDate={params[dateFilter.after] && new Date(params[dateFilter.after])} endDate={params[dateFilter.before] && new Date(params[dateFilter.before])} selected={params[dateFilter.after] && new Date(params[dateFilter.after])} maxDate={params[dateFilter.before] ? new Date(params[dateFilter.before]) : new Date()} onChange={date => { setParams({ [dateFilter.after]: date ? moment(date).format() : null }); setLabel(date || params[dateFilter.before] ? 'Custom' : presets[0].title)} } /></> } 
							{ duration ? 'Ends At' : 'Before' } <DatePicker placeholderText="Click to select a date" dateFormat="MM/dd/yyyy h:mm aa" showTimeInput isClearable selectsEnd startDate={params && params[dateFilter.after] && new Date(params[dateFilter.after])} endDate={params && params[dateFilter.before] && new Date(params[dateFilter.before])} selected={!params ? null : duration ? new Date(params) : params[dateFilter.after] && new Date(params[dateFilter.before])} maxDate={!duration && new Date()} minDate={duration ? new Date () : params && (params[dateFilter.after] && new Date(params[dateFilter.after]))} onChange={date => { duration ? setParams(date ? moment(date).format() : null) : setParams({ [dateFilter.before]: date ? moment(date).format() : null }); setLabel(date || params[dateFilter.after] ? 'Custom' : presets[0].title)} }  />
						</> :
						presets.map(preset => 
							<Preset key={preset.title} selected={label === preset.title} onClick={() => {setLabel(preset.title); duration ? setParams(preset.before) : setParams({ [dateFilter.after]: preset.after, [dateFilter.before]: preset.before})}}>{preset.title}</Preset>
						)
					}
				</DateRange>
			}
		</DatePickerDiv>
	)
}

const now = new Date();
const defaultPresets = [
	{ title: 'Any', after: null, before: null },
	{ title: 'Last 30 minutes', after: moment(now).subtract(30, 'minutes').format(), before: null},
	{ title: 'Last 2 hours', after: moment(now).subtract(2, 'hours').format(), before: null},
	{ title: 'Today', after: moment().startOf('day').format(), before: null},
	{ title: 'Yesterday', after: moment().startOf('day').subtract(1, 'day').format(), before: moment().startOf('day').format()},
	{ title: 'Last 24 hours', after: moment(now).subtract(24, 'hours').format(), before: null},
	{ title: 'Last 7 days', after: moment(now).subtract(7, 'days').format(), before: null},
	{ title: 'Last 30 days', after: moment(now).subtract(30, 'days').format(), before: null}
]
const durationPresets = [
	{ title: 'Permanent', before: null },
	{ title: '30 minutes', before: moment(now).add(30, 'minutes').format() },
	{ title: '2 hours', before: moment(now).add(2, 'hours').format() },
	{ title: '24 hours', before: moment(now).add(24, 'hours').format() },
	{ title: '7 days', before: moment(now).add(7, 'days').format() },
	{ title: '30 days', before: moment(now).add(30, 'days').format() }
]

const DatePickerDiv = styled.div` 
	background-color: ${props => props.theme.colors.backgroundDim};
	display: flex;
	font-size: 1.8rem;
	flex-direction: column;
	width: 100%;
	${props => props.$dark && `border: solid 0.3rem ${props.theme.colors.backgrounds[1]};`}
	& > div:first-child {
		display: flex;
		align-items: center;
		padding-left: 0.5rem;
		position: relative;
	}
	input.react-datepicker-time__input, .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name, .react-datepicker, .react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header, .react-datepicker__day-name, .react-datepicker__input-container input  {
		color: ${props => props.theme.colors.text};
	}
	.react-datepicker__input-container input, input.react-datepicker-time__input {
		width: 100%;
		background-color: ${props => props.theme.colors.backgrounds[1]};
		border: none;
		padding: 0.375rem;
		font-size: 1.6rem;
	}
	.react-datepicker__day-name, .react-datepicker__day {
		margin: 0.3rem;
		line-height: 2.8rem;
		width: 2.8rem;
	}	
	.react-datepicker {
		border-color: ${props => props.theme.colors.backgrounds[6]}; 
		background-color: ${props => props.theme.colors.backgroundLight};
		font-size: 1.6rem;
		box-shadow: ${props => props.theme.shadow};
	}
	.react-datepicker__current-month {
		font-size: 1.9rem;
	}
	.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::after {
		border-bottom-color: ${props => props.theme.colors.backgrounds[4]};
	}
	.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::after {
		border-top-color: ${props => props.theme.colors.backgroundLight};
	}
	.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
		border-top-color: ${props => props.theme.colors.backgrounds[6]};
		border-bottom-color: ${props => props.theme.colors.backgrounds[6]};
		
	}
	.react-datepicker__close-icon::after {
		background-color: ${props => props.theme.colors.accent};
	}
	.react-datepicker__header {
		background-color: ${props => props.theme.colors.backgrounds[4]};
		border-color: ${props => props.theme.colors.backgrounds[6]};
		padding-top: 1.2rem;
	}
	.react-datepicker__navigation {
  		top: 0.6rem;
	}
	.react-datepicker__day--in-range {
		background-color: ${props => props.theme.colors.grayedText};
	}
	.react-datepicker__day--in-selecting-range {
		background-color: ${props => props.theme.colors.accentDim};
	}
	.react-datepicker__day--keyboard-selected {
		background-color: ${props => props.theme.colors.accentDim};
		color: ${props => props.theme.colors.text};
	}
	.react-datepicker__day:hover {
		background-color: ${props => props.theme.colors.grayedText};
	}
	.react-datepicker__day--disabled {
		color: ${props => props.theme.colors.grayedText};
		&:hover {
			background-color: inherit;
		}
	}
	.react-datepicker__day--selecting-range-end {
		&:hover {
			background-color: ${props => props.theme.colors.accent};
			color: ${props => props.theme.colors.backgrounds[4]};
		}
	}
	.react-datepicker__day--selecting-range-start {
		background-color: ${props => props.theme.colors.accentDim};
		&:hover {
			background-color: ${props => props.theme.colors.accent};
			color: ${props => props.theme.colors.backgrounds[4]};
		}
	}
	.react-datepicker__day--selected {
		background-color: ${props => props.theme.colors.accent};
		color: ${props => props.theme.colors.backgrounds[4]};
		&:hover {
			background-color: ${props => props.theme.colors.accentDim};
		}
	}
`;

const DateSelector = styled(FontAwesomeIcon)` 
	font-size: 2rem;
	padding: 0.5rem;
	${props => props.$showing && `color: ${props.theme.colors.accent};`}
	background-color: ${props => props.theme.colors.backgrounds[1]};
	margin-left: auto;
	cursor: pointer;
`;

const DateRange = styled.div` 
	display: flex;
	flex-direction: column;
	gap: 0.375rem;
	padding: 0.375rem;
	width: 100%;
	& > div {
		background-color: ${props => props.$alt ?  props.theme.colors.backgrounds[4] : props.theme.colors.background};
		width: 100%;
		padding: 0.375rem;
		display: flex;
		justify-content: center;
		gap: 0.375rem;
		cursor: pointer;
	}
	& > div:first-child {
		justify-content: space-around;
		gap: 0.375rem;
		padding: 0;
		background-color: ${props => props.theme.colors.backgroundDim};
		& > div {
			background-color: ${props => props.alt ?  props.theme.colors.backgrounds[4] : props.theme.colors.background};
			width: 100%;
			padding: 0.375rem;
			display: flex;
			justify-content: center;
			cursor: pointer;
		}
		& > div:first-child {
			border-bottom: solid 1px ${props => !props.custom ? props.theme.colors.accent : props.theme.colors.background}
		}
		& > div:last-child {
			border-bottom: solid 1px ${props => props.custom ? props.theme.colors.accent : props.theme.colors.background}
		}
	}
`;

const Preset = styled.div` 
	border-bottom: solid 0.1rem ${props => props.selected ? props.theme.colors.accent : props.theme.colors.background};
`;

export default RustyDatePicker
