// Module imports.
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCircleNotch } from '@fortawesome/free-solid-svg-icons';

// TODO: Cleanup styled components.

const RustyLoading = ({ message, small, full, noMargin }) => (
	<Loading full={full} small={small} noMargin={noMargin}>
		{ message && <div>{message}</div> }
		<FontAwesomeIcon icon={ small ? faCircleNotch : faSpinner} />
	</Loading>
);

const Loading = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	${props => props.full && `width: 100%;`}
	color: ${props => props.theme.colors.accent}; 
	div {
		font-size: 5rem;
		margin-bottom: 0.5rem;
	}
	svg {
		${props => !props.noMargin && `margin: 1rem;`}
		font-size: 5rem;
		animation: ${props => props.theme.animations.rotate} 1s cubic-bezier(0.4, 0.6, 0.3, 1.5) infinite; 
	}
	${props => props.small && `
	position: absolute;
	svg {
		font-size: 1.8rem;
	}
	right: .3rem;
	height: 100%;
	margin: 0;
	`}
`;

export default RustyLoading;
