// Module imports.
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const Spinner = () => (
	<Loading>
		<div>
			<FontAwesomeIcon icon={faCircleNotch} />
			<FontAwesomeIcon icon={faCircleNotch} />
		</div>
	</Loading>
);

const Loading = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	& > div {
		font-size: 5rem;
		position: relative;
		& > svg {
			top: -1.5rem;
			left: -1.5rem;
			position: absolute;
			font-size: 3rem;
			animation: ${props => props.theme.animations.rotate} 1s infinite; 
		}
		& > svg:last-child {
			top: -2.5rem;
			left: -2.5rem;
			font-size: 5rem;
			animation: ${props => props.theme.animations.rotateReverse} 1.5s infinite; 
		}
	}
`;

export default Spinner;
