import io from 'socket.io-client';

const socket = io(window.location.origin.replace('rustinity', 'rusticated').replace('limitlessrust', 'rusticated'), {
	path: '/api/v2/socket',
	transports: ['websocket'],
	autoConnect: false,
	authToken: ''
});

const subscriptions = {};

socket.on('connect', () => {
	console.log('Socket connected, authorizing...');
	socket.emit('authorize', socket.authToken);
});

socket.on('authorized', (authResponse = {}) => {
	if (!authResponse.authed) {
		console.error('Socket auth rejected:', authResponse.reason || authResponse);
		return;
	}

	console.log('Socket authorized');
	// Resubscribe to stuff yeah
	for (let subscriptionPath in subscriptions) {
		const key = subscriptions[subscriptionPath]
		socket.subscribeToData(subscriptionPath, key);
	}
});

socket.on('disconnect', () => {
	console.log('Socket disconnected');
});

socket.subscribeToData = (subscriptionPath, key) => {
	subscriptions[subscriptionPath] = key;
	socket.emit(`sub:${subscriptionPath}`, key);
};

socket.unsubscribeToData = (subscriptionPath, key) => {
	subscriptions[subscriptionPath] = key;
	socket.emit(`unsub:${subscriptionPath}`, key);
};

socket.isSubscribed = (subscriptionPath, key) => subscriptions[subscriptionPath] === key;

export default socket;
