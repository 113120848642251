import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";

import { useStoreService } from "./store";
import useLoadMyInfo from "./hooks/useLoadMyInfo";
import theme from "./theme";
import ErrorBoundary from "./component/ErrorBoundary";
import Spinner from "./component/Spinner";
import Topbar from "./component/Topbar";
import PrivateRoute from "./component/PrivateRoute";
import Dashboard from "./pages/Dashboard";
import ServiceAuth from "./pages/Auth";
import Leaderboards from "./pages/Leaderboards";

const Support = React.lazy(() => import("./pages/Support"));
const Account = React.lazy(() => import("./pages/Account"));

const TermsOfService = React.lazy(() => import("./pages/TermsOfService"));
const PrivacyPolicy = React.lazy(() => import("./pages/PrivacyPolicy"));

const App = () => {
  const {
    state: { authorized, me },
  } = useStoreService();
  useLoadMyInfo();

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SiteWrapper>
          <ErrorBoundary>
            <Topbar />
            <React.Suspense fallback={<Spinner />}>
              <ErrorBoundary>
                <Routes>
                  <Route index element={<Dashboard />} />
                  <Route path="leaderboards" element={<Leaderboards />} />
                  <Route path="terms-of-service" element={<TermsOfService />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route
                    path="/service-auth/:service"
                    element={<ServiceAuth />}
                  />
                  <Route
                    element={<PrivateRoute authorized={authorized} me={me} />}
                  >
                    <Route path="support" element={<Support />} />
                    <Route path="account/*" element={<Account />} />
                  </Route>
                  <Route path="*" element={<Navigate to="" replace />} />
                </Routes>
              </ErrorBoundary>
            </React.Suspense>
          </ErrorBoundary>
        </SiteWrapper>
      </ThemeProvider>
    </BrowserRouter>
  );
};

const SiteWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.text};
  background-color: ${(props) => props.theme.colors.backgroundDim};
  overflow: hidden;
`;

export default App;
