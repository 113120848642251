import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useStoreService } from "../store/index.jsx";

const RustyTagStyle = styled(Link)`
  ${(props) => (!props.$normal ? `font-size: 65%;` : `font-size: 85%;`)}
  padding: 0.35rem;
  background-color: ${(props) => props.color};
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  display: inline-flex;
  align-items: center;
`;

function RustyTag({ normal, roleId, color, name, noLink }) {
  const {
    state: { user: userData },
  } = useStoreService();
  let showAdminStuff = userData?.showAdminStuff;

  return (
    <RustyTagStyle
      as={showAdminStuff && !noLink ? Link : "div"}
      $normal={normal}
      color={color}
      to={`/users?roleId=${roleId}`}
    >
      {name}
    </RustyTagStyle>
  );
}

export default RustyTag;
