// Module imports.
import React, { useEffect } from 'react';
import styled from 'styled-components';

// IMO this should be done server side but, since it isn't I will leave this here till it is.

// TODO: Cleanup all of component.
 
const RustyPagination = ({ params, setParams, totalResults }) => {
	const page = (params.offset / params.limit) + 1;
	const totalPages = Math.ceil(totalResults / params.limit);
	const setPage = page => setParams({ offset: params.limit * (page - 1) });

	// Is this awful? Who knows?
	useEffect(() => {
		if (page > 1) {
			setPage(1);
		}
	}, [JSON.stringify({ ...params, limit: null, offset: null })])

	return (
		<PaginationDiv>
			<span>{totalResults === 10000 && '>'}{totalResults} results</span>
			{ totalPages > 1 &&
			<>
				<PageSelector grayOut={page === 1} onClick={() => {setPage(1)}}>First</PageSelector>
				{page > 1 && <PageSelector onClick={() => setPage(page - 1)}>Previous</PageSelector>}
				<PageList page={page} setPage={setPage} totalPages={totalPages}/>
				{page < totalPages && <PageSelector onClick={() => setPage(page + 1)}>Next</PageSelector>}
				<PageSelector grayOut={page === totalPages} onClick={() => setPage(totalPages)}>Last</PageSelector>
			</>
			}
		</PaginationDiv>
	)
}

const PageList = ({ page, setPage, totalPages }) => {
	let pages = [], firstOption = page - 2;
	if (page <= 3) {
		firstOption = 1;
	} else if (page > totalPages - 3) {
		firstOption = totalPages - 4;
	}
	while (pages.length <= 4) {
		if (firstOption >= 1 && firstOption <= totalPages) {
			pages.push(firstOption);
		} else {
			break;
		}
		firstOption++;
	}
	return (
		<Pages>
			{pages && pages.map(pageOption => (
				<PageSelector key={pageOption} page={pageOption === page} onClick={() => setPage(pageOption)}>{pageOption}</PageSelector>
			))}
		</Pages>
	) 
}

const PaginationDiv = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-top: auto;
	font-size: 1.6rem;
	padding: 1rem 0 0.5rem;
	& > span {
		padding: 0.25rem 0.75rem;
		border-bottom: rgb(0,0,0,0) solid .3rem;
		margin-right: auto;
	}
`;

const PageSelector = styled.div`
	${props => (!props.grayOut && !props.page) && `
	&:hover {
		cursor: pointer;
		color: ${props.theme.colors.accent};
	}
	`}
	padding: 0.25rem 0.75rem;
	margin: 0 .5rem;
	border-bottom: rgb(0,0,0,0) solid .3rem;
	${props => props.page && `
	border-bottom: ${props.theme.colors.accent} solid .3rem;
	color: ${props.theme.colors.grayedText};
	`}
	${props => props.grayOut && `
	color: ${props.theme.colors.grayedText};
	`}
	${props => props.mobileShow && `
	display: none;
	`}
`;

const Pages = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export default RustyPagination;

