import { useEffect, useState } from 'react';

// Kinda hate this but oh well.

// TODO : Make it... better...

const useScroll = (defaultParams) => {
	const searchParams = new URLSearchParams(window.location.search);
	const getParams = (params) => {
		params = params || searchParams;
		return Object.fromEntries( Array.from(new Set(params.keys())).map(value => {
			return [ value, params.getAll(value).length > 1 ? params.getAll(value) : params.get(value) ]
		})
	)}

	const [ params, setParamsState ] = useState({ ...defaultParams, ...getParams(searchParams) })

	const setParams = (newParams) => {
		setParamsState(params => ({ ...params, ...newParams }))
		updateParams({ ...params, ...newParams })
	}

	const setHas = (key, value) => new Set(params[key]).has(value);

	const setParamsSet = (key, value) => {
		setParamsState(params => {
			const set = new Set(params[key] ? Array.isArray(params[key]) ? params[key] : [params[key]] : null);
			set.has(value) ? set.delete(value) : set.add(value);
			updateParams({ ...params, [key]: Array.from(set) });
			return { ...params, [key]: Array.from(set) };
		})
	}

	const updateParams = async (params) => {
		const newParams = new URLSearchParams();
		Object.entries(params).forEach(([key, value]) => 
			Array.isArray(value) ? value.forEach(value => value && newParams.append(key, value)) : value && newParams.append(key, value)
		)
		window.history.pushState(null, document.title, window.location.href.split('?')[0] + (newParams.toString() && "?" + newParams.toString()));
		return params;
	}

	useEffect(() => {
        window.onpopstate = () => {
            const updatedParams = (new URL(document.location)).searchParams;
            setParamsState({ ...defaultParams, ...getParams(updatedParams)})
        }
        return () => window.onpopstate = null;
    })

	useEffect(() => {
		if (window.location.search === '') {
			const updatedParams = (new URL(document.location)).searchParams;
            setParamsState({ ...defaultParams, ...getParams(updatedParams)})
		}
	}, [window.location.search])

	return { params, setParams, setHas, setParamsSet, setParamsState }
}

export default useScroll;
