// Module imports.
import React from 'react';
import styled from 'styled-components';

// TODO: Cleanup styled components.

const RustySwitch = ({ name, options, params, setParams, autoWidth, vertical }) => {
	return (
		<Switch vertical={vertical} autoWidth={autoWidth}>
			{ (options.length > 1 || autoWidth) && options.map(option => 
				<Option autoWidth={autoWidth} onClick={() => setParams({ [name]: option.value })} key={option.name} selected={params[name] === option.value}>{option.name}</Option>
			)}
		</Switch>
	)
}

const Switch = styled.div`
	display: flex;
	align-items: center;
	font-size: 1.75rem;
	cursor: pointer;
	${props => !props.autoWidth ? `background-color: ${props.theme.colors.backgrounds[4]}; width: 100%;` : 'gap: 1rem;'}
	${props => props.vertical && 'flex-direction: column; background-color: none; width: 100%; gap: 0.5rem;'}
`;

const Option = styled.div`
	text-align: center;
	padding: 0.5rem;
	white-space: nowrap;
	border-bottom: 0.1rem solid ${props => props.theme.colors.backgrounds[4]};
	${props => !props.autoWidth && 'width: 100%;'}
	${props => props.selected && 
	`background-color: ${props.theme.colors.grayedText};
	 border-bottom: 0.1rem solid ${props.theme.colors.accent};
	`
	}
	${props => (!props.selected && props.autoWidth) && 
		`background-color: ${props.theme.colors.backgrounds[4]};`
	}
`;

export default RustySwitch;

