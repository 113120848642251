import React, { useEffect } from "react";
import styled from "styled-components";

import HeatmapOverlay from "heatmap.js/plugins/leaflet-heatmap/leaflet-heatmap";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

import RustyLoading from "../../../component/RustyLoading.jsx";
import RustyError from "../../../component/RustyError.jsx";

import useApi from "../../../hooks/useApi.js";
import { useStoreService } from "../../../store/index.jsx";

let map = null;

const Heatmaps = ({ params }) => {
  const {
    state: { orgId },
  } = useStoreService();
  const {
    data: gameMap,
    error: mapError,
    isLoading: isMapLoading,
  } = useApi(
    {
      url: `/servers/${params.serverId}/heatmaps/map`,
      params: { orgId: orgId },
    },
    { callOnMount: Boolean(params.serverId), useDebounce: false }
  );
  const {
    data: heatmapBuffer,
    error: dataError,
    isLoading: isDataLoading,
    callApi,
  } = useApi(
    {
      url: `/servers/${params.serverId}/heatmaps/data`,
      params: { ...params, orgId: orgId },
      responseType: "arraybuffer",
    },
    { callOnMount: Boolean(params.serverId), useDebounce: false }
  );

  const imageUrl =
    params.showIcons === "true"
      ? gameMap?.imageUrl
      : gameMap?.imageUrl?.replace("FullLabeledMap.png", "FullMap.png");

  const currentMapId = `${params?.serverId ?? "none"}-${
    params?.statType ?? "none"
  }-${params?.since}-${params?.showIcons}`;

  useEffect(() => {
    if (!gameMap || !heatmapBuffer || heatmapBuffer.byteLength === 0) return;

    const heatmapDataView = new DataView(heatmapBuffer);

    const imageMapSize =
      document.getElementById(currentMapId)?.offsetWidth ?? 1000;
    const mapSize = gameMap.mapSize;
    const mapHalf = mapSize / 2;

    const radius = (imageMapSize / 1000) * 15;

    let mappedData = [];
    let highestValue = 0;

    for (let i = 0; i < heatmapDataView.byteLength; i += 8) {
      const x = heatmapDataView.getInt16(i, true);
      const z = heatmapDataView.getInt16(i + 2, true);
      const count = heatmapDataView.getUint32(i + 4, true);
      if (count > highestValue) {
        highestValue = count;
      }

      mappedData.push({
        x: ((z + mapHalf) / mapSize) * imageMapSize,
        y: ((x + mapHalf) / mapSize) * imageMapSize,
        value: count,
      });
    }

    const testData = {
      max: highestValue,
      data: mappedData,
    };

    const imageBounds = [
      [imageMapSize, 0],
      [0, imageMapSize],
    ];
    const baseLayer = L.imageOverlay(imageUrl, imageBounds);

    const cfg = {
      radius: radius,
      maxOpacity: 0.5,
      scaleRadius: false,
      // Local extrema scales the max value depending on the zoom level (if zoomed in, there will be always something red)
      useLocalExtrema: true,
      latField: "x",
      lngField: "y",
      valueField: "value",
    };

    const heatmapLayer = new HeatmapOverlay(cfg);

    if (map) {
      map.off();
      map.remove();
    }

    map = new L.Map(currentMapId, {
      maxZoom: 3,
      minZoom: 0,
      crs: L.CRS.Simple,
      attributionControl: null,
    });

    map.addLayer(baseLayer);

    map.setView([imageMapSize / 2, imageMapSize / 2], 0);
    map.setMaxBounds(new L.LatLngBounds([0, imageMapSize], [imageMapSize, 0]));

    setTimeout(() => {
      heatmapLayer.setData(testData);
      map.addLayer(heatmapLayer);
    }, 20);
  }, [gameMap, heatmapBuffer?.byteLength, imageUrl]);

  return (
    <MapContainer>
      {mapError || dataError ? (
        <RustyError error={mapError || dataError} retry={callApi} />
      ) : (
        (isMapLoading || isDataLoading) && <RustyLoading />
      )}

      {gameMap && (
        <MapInner loading={isMapLoading || isDataLoading} id={currentMapId} />
      )}
    </MapContainer>
  );
};

const MapContainer = styled.div`
  margin-left: 1rem;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  width: 80.5%;
  height: 100%;

  ${(props) => props.theme.tablet} {
    width: 100%;
    margin-left: 0;
    margin-bottom: 2rem;
  }
`;

const MapInner = styled.div`
  height: 80vh;
  width: 80vh;

  ${(props) => props.loading && "display: none;"}

  ${(props) => props.theme.tablet} {
    width: 90vw;
    height: 90vw;
  }

  .leaflet-zoom-hide {
    z-index: 999;
  }
`;

export default Heatmaps;
