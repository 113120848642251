import styled from 'styled-components';
import _RustyTag from './RustyTag.jsx';

//TODO: RustyEmpty for when no data is returned.

export const RustyHeader = styled.div`
	display: flex;
	align-items: center;
	gap: 0.75rem;
	${props => !props.small && `height: 5rem; flex-shrink: 0;`}
	font-size: ${props => props.small ? '1.1em' : '3.25rem'};
	${props => props.pad && 'padding-bottom: 0.375rem;'}
	${props => props.bold && 'font-weight: bold;'}
	${props => props.pointer && 'cursor: pointer;'}
	${props => props.uppercase && 'text-transform: uppercase;'}
`;

export const RustyRow = styled.div`
	${props => props.space && `
	display: flex;
	justify-content: space-between;
	`}
	${props => props.column && `
	display: flex;
	flex-direction: column;
	gap: 0.75rem;
	`}
	&:last-child {
		margin-bottom: 0;
	}
	${props => props.scroll && 'margin-right: 0.9rem' };
	${props => props.bottom && 'margin-top: auto;'}
	padding: ${props => props.nopad ? '0' : props.nobuff ? '0 0.75rem' : props.small ? '0.375em' : props.tiny ? '0.3rem 0.375em' : '0.75rem'};
	background-color: ${props => props.transparent ? 'transparent' : props.theme.colors.background};
	animation: ${props => props.theme.animations.fadeDownIn} 500ms;
	&:hover {
		background-color: ${props => props.transparent ? 'transparent' : props.theme.colors.backgrounds[4]};
	}
	& > span {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	& > h3 {
		display: flex;
		align-items: center;
		gap: 1rem;
	}
`;

export const RustyContent = styled.div` 
	${props => props.center && `
	display: flex;
	justify-content: center;
	`}
	background-color: ${props => props.theme.colors.backgroundDim};
	min-height: 3em;
	padding: 0.75rem;
	user-select: text;
	word-wrap: break-word;
	white-space: pre-wrap;
`;

export const RustyList = styled.div` 
	display: grid;
	grid-gap: 0.5rem;
	grid-template-columns: ${props => `repeat(${props.cols || 2}, 1fr)`};
	${props => !props.transparent && `
	background-color: ${props.theme.colors.backgroundDim};
	padding: 0.5rem;
	`}
	overflow-y: auto;
	${props => props.theme.scrollbar}
	position: relative;
	& > *:nth-child(${props => (props.cols || 2) + 'n' }) {
		${props => props.scroll && 'margin-right: 0.375rem' };
	}
`;

export const RustyListItem = styled.div` 

`;

export const RustyScroll = styled.ol` 
	overflow-y: auto;
	${props => props.theme.scrollbar}
	display: flex;
	flex-direction: column;
	gap: ${props => props.skinny ? '0.5rem' : props.nogap ? '0' : '1.25rem'};
	& > * {
		${props => props.scroll && 'margin-right: 0.375rem' };
	}
	position: relative;
`;

export const RustyAction = styled.div` 
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 2px solid;
	border-color: ${props => 
	props.negative ? props.theme.colors.negative:
	props.$warning ? props.theme.colors.warning:
	props.positive ? props.theme.colors.positive:
	props.theme.colors.accent};
	padding: ${props => props.$small ? '0.375rem' : '0.75rem' };
	font-weight: bold;
	cursor: pointer;
	gap: 0.75rem;
	font-size: ${props => props.$small ? '1.375rem' : '2rem' };
	user-select: none;
	height: max-content;
	&:hover {
		background-color: ${props => props.theme.colors.backgroundLight};
	}
	${props => props.$gapleft && 'margin-left: auto;'}
	${props => props.wide && 'width: 100%;'}
	${props => props.$gapSides && 'margin-left: 1em; margin-right: 1em;'}
	min-width: 2.5rem;
	min-height: 2.5rem;
	${props => props.$noShrink && `flex-shrink: 0`};
`;

export const RustyAvatar = styled.img` 
	width: 3rem;
	height: 3rem;
	${props => props.color && `box-shadow: 0 0 0 0.2rem ${props.color};`}
	${props => !props.square && `border-radius: 100%;`}
`;

export const RustyInput = styled(RustyContent)` 
	resize: none;
	border: none;
	outline: none;
	font-family: inherit;
	color: inherit;
	height: 5em;
	${props => props.theme.scrollbar}
`;

export const Span = styled.span` 
	${props => props.$gapleft && 'margin-left: auto;' }
	${props => props.$gapright && 'margin-right: auto;' }
	${props => props.select && 'user-select: all;' }
	text-transform: ${props =>
	props.capitalize && 'capitalize'
	};
	font-weight: ${props => 
	props.bold ? 'bold':
	props.normal && 'normal'
	};
	color: ${ props => 
	props.inherit ? 'inherit':
	props.subtext ? props.theme.colors.subtext:
	props.gray ? props.theme.colors.grayedText:
	props.positive ? props.theme.colors.positive:
	props.negative ? props.theme.colors.negative:
	props.accent ? props.theme.colors.accent:
	props.posneg ? props.theme.colors.positive : props.theme.colors.negative
	};
	&:hover {
		${props => props.hover && `color: ${props.theme.colors.text};`}
		${props => props.underline && `
			text-decoration: underline;
			cursor: pointer;
			color: ${props.theme.colors.accent};
		`}
	}
	${props => props.onClick && `
		user-select: none;
	`}
	${props => props.nowrap && `
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	`}
`;

// Didn't wanna rewrite imports
export const RustyTag = _RustyTag;
