// Module imports.
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

// Project imports.
import { RustyAction } from './RustyStyles.jsx';

// TODO: Cleanup styled compontents.

const RustyError = ({ error, redirect, retry, border }) => (
	<Error border={border} >
		<div>
			<div><FontAwesomeIcon icon={faExclamationTriangle} />{(error && error.status) && <h1>{error.status}</h1>}</div>
			<h3>{(error && error.message) || "An unknown error occurred."}</h3>
			{ retry ? 
				<RustyAction onClick={() => retry()} >Retry <FontAwesomeIcon icon={faRedo} /></RustyAction>
			: redirect &&
				<RedirectCountdown time={redirect.time} url={redirect.url}/>
			}
		</div>
	</Error>
);

const RedirectCountdown = ({ time = 5, url = '/' }) => {
	const [ timeLeft, setTimeLeft ] = React.useState(time);

	React.useEffect(() => {
		const interval = setInterval(() => {
			setTimeLeft(currentTime => {
				if (currentTime <= 1) {
					clearInterval(interval);
					window.location = url;
				}
				return(currentTime - 1)
			});
		}, 1000);
		return () => clearInterval(interval);
	}, [url]);

	return (
		<>
			<div>Redirecting in {timeLeft}...</div>
			{ timeLeft <= 1 &&
				<div>If you are not automatically redirected <a href={url}>click here</a></div>
			}
		</>
	)
}

const Error = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-size: 2em;
	height: 100%;
	& > div > {
		div, h1, h3 {
			margin: 0.5em; 
		}
		div:first-child {
			display: flex;
			justify-content: center;
			align-items: center;
			svg {
				font-size: 1.3em;
				color: ${props => props.theme.colors.negative};
			}
			h1 {
				margin-left: 0.3em;
			}
		}
	}
	${props => props.border && `
	 	& > div {
			padding: 2rem;
			background-color: ${props.theme.colors.backgrounds[2]};
			border: solid 2px ${props.theme.colors.backgrounds[3]};
			box-shadow: ${props.theme.shadow};
		}
	`}
`;

export default RustyError;
