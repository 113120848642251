import React from "react";
import styled from "styled-components";

class ErrorBoundary extends React.Component {

	state = { hasError: false, error: null };

	static getDerivedStateFromError(error) {
		return {
			hasError: true,
			error,
		};
	}

	componentDidCatch(error, errorInfo) {   
		// You can also log the error to an error reporting service    
		console.error(error); 
	}

	render() {
		if (this.state.hasError) {
			return (
				<ErrorContainer>
          			Oops, something went wrong... try refreshing your browser!
					<div>
						{this.state.error.response?.data?.message || this.state.error.toString()}
					</div>
        		</ErrorContainer>
			)
		}
		return this.props.children;
	}
}

const ErrorContainer = styled.div`
	display: flex;
	align-items: center;
	text-align: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	width: 100%;
	flex: 1;
	font-size: 2rem;
	gap: 1rem;
	& > div {
		background-color: ${props => props.theme.colors.backgroundDark};
		padding: 2rem;
	}
`

export default ErrorBoundary;
