import { useEffect } from 'react';
import { useStoreService } from '../store/index.jsx';

const useLoadMyInfo = () => {
	const StoreService = useStoreService();

	useEffect(() => {
		console.log(`Loading`);
		StoreService.loadAuthorization();
	}, [StoreService]);

	useEffect(() => {
		StoreService.fetchMe();
	}, [StoreService, StoreService.state.authorized]);

}

export default useLoadMyInfo;
