import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { RustyTag } from "./RustyStyles.jsx";
import RustySessionsStatus from "./RustySessionStatus.jsx";
import { useStoreService } from "../store/index.jsx";

// Link to steam page or admin site depending on admin status, with correct redirecting.
// If user is not admin don't show role search link.

const RustyUser = ({
  user,
  name,
  avatar,
  notag,
  noSession,
  noName,
  noAvatar,
}) => {
  const {
    state: { me: userData },
  } = useStoreService();
  let showAdminStuff = userData?.showAdminStuff;
  user = user || {};

  let steamLink = showAdminStuff
    ? {}
    : {
        target: "_blank",
        rel: "noopener noreferrer",
        href: `http://steamcommunity.com/profiles/${user.steamId}`,
      };

  return (
    <User>
      {!noAvatar &&
        (user.avatar || avatar ? (
          <Avatar
            $noSession={noSession}
            as={showAdminStuff ? Link : "a"}
            {...steamLink}
            color={(avatar || notag) && user.roleColor}
            to={`/users/${user.id}`}
            title={name || user.name || user.id}
          >
            <img src={user.avatar || avatar} alt="" />
            {!noSession && showAdminStuff && (
              <RustySessionsStatus
                absolute
                noServer
                noLabel
                session={user.lastSession}
              />
            )}
          </Avatar>
        ) : (
          <NoAvatar>
            <FontAwesomeIcon icon={faUser} />
          </NoAvatar>
        ))}
      {((!noName && name) || !avatar) && (
        <Name
          $link={noAvatar}
          as={noAvatar ? Link : "span"}
          to={`/users/${user.id}`}
        >
          {name || user.name || user.id || <em>Unknown</em>}
        </Name>
      )}
      {((!noName && name) || !avatar) && !notag && user.roleId && (
        <RustyTag
          roleId={user.roleId}
          color={user.roleColor}
          name={user.roleName}
        />
      )}
    </User>
  );
};

const User = styled.span`
  display: inline-flex;
  align-items: center;
  gap: 1rem;
`;

const Avatar = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  ${(props) => !props.$noSession && `margin: 0.25rem`};
  img {
    ${(props) => props.color && `box-shadow: 0 0 0 0.2rem ${props.color};`}
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
  }
`;

const NoAvatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.colors.background};
  svg {
    margin-top: 0.5rem;
    margin-left: 0.2rem;
    font-size: 2.85rem;
    color: ${(props) => props.theme.colors.accentDim};
  }
`;

const Name = styled.span`
  ${(props) =>
    props.$link &&
    `
	&:hover {
		text-decoration: underline;
		color: ${props.theme.colors.accent}
	}
	`}
`;

export default RustyUser;
