import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const RustySort = ({ children, sortKey, sortBy, sortDir, setParams, accent, small }) => {
	const selected = sortKey === sortBy;
	const sortDesc = sortDir === 'desc';
	return (
		<Sort small={small} accent={accent} onClick={() => setParams({ sortBy: sortKey, sortDir: selected ? (sortDesc ? 'asc' : 'desc') : 'desc' })}>
			{children}
			{ selected ? 
				<FontAwesomeIcon icon={sortDesc ? faSortDown : faSortUp}/> : 
				<FontAwesomeIcon icon={faSort}/>
			}
		</Sort>
	)
}

const Sort = styled.span` 
	display: flex;
	gap: 0.375rem;
	align-items: center;
	cursor: pointer;
	${props => props.small && `font-size: 0.7em;`}
	${props => props.accent && `color: ${props.theme.colors.accent};`}
	&:hover {
		color: ${props => props.accent ? props.theme.colors.text : props.theme.colors.accent}
	}
`;

export default RustySort
