import React from "react";
import styled from "styled-components";

import RustyLoading from "../../../component/RustyLoading.jsx";
import RustyPagination from "../../../component/RustyPagination.jsx";
import useApi from "../../../hooks/useApi.js";
import useScroll from "../../../hooks/useScroll.js";
import RustySort from "../../../component/RustySort.jsx";
import RustyError from "../../../component/RustyError.jsx";
import { RustyScroll, Span } from "../../../component/RustyStyles.jsx";
import RustyUser from "../../../component/RustyUser.jsx";
import RustySwitch from "../../../component/RustySwitch.jsx";

const Leaders = ({ params, setParams, isClans = false }) => {
  const {
    data: leaders,
    error,
    isLoading,
    callApi,
  } = useApi(
    {
      url: `/servers/${params.serverId}/leaderboard`,
      params: { ...params, orgId: "2", isClans },
    },
    { callIfTruthy: params.serverId ? true : false }
  );
  useScroll({ data: leaders, enableScrolling: "up" });

  const availableStatGroupOptions = [
    { name: "PVP", value: "pvp" },
    { name: "PVE", value: "pve" },
    { name: "Gambling", value: "gambling" },
    { name: "Looted", value: "looted" },
    { name: "Building", value: "building" },
    { name: "Items placed", value: "item_placed" },
    { name: "Recycled", value: "recycled" },
    { name: "Gathered resources", value: "gathered" },
    { name: "Gathered food", value: "food_gathered" },
    { name: "Bought items", value: "bought" },
    { name: "Explosives used", value: "boom" },
    { name: "Fired bullets", value: "bullets_fired" },
    { name: "Bullet hits", value: "bullets_hit" },
  ];

  const isPVP = params?.statGroup === "pvp" || !params?.statGroup;

  const statTypeData = leaders?.statTypesData;
  let totalColumns = statTypeData?.length ?? 0;
  if (totalColumns < 1 || isPVP) {
    totalColumns = 4;
  }

  return (
    <LeaderboardsContainer>
      <LeaderboardsGroupSelector>
        <RustySwitch
          autoWidth
          name="statGroup"
          options={availableStatGroupOptions}
          params={params}
          setParams={setParams}
        />
      </LeaderboardsGroupSelector>

      <Leaderboards>
        <LeaderboardsInnerContainer>
          {leaders && isLoading && <RustyLoading small />}
          <LeadersHeader totalColumns={totalColumns} isRanked={isPVP}>
            {isPVP ? (
              <>
                <span>Rank</span>
                <span>Player</span>
                <RustySort
                  accent
                  sortKey="kills"
                  sortDir={params.sortDir}
                  sortBy={params.sortBy}
                  setParams={setParams}
                >
                  Kills
                </RustySort>
                <RustySort
                  accent
                  sortKey="deaths"
                  sortDir={params.sortDir}
                  sortBy={params.sortBy}
                  setParams={setParams}
                >
                  Deaths
                </RustySort>
                <RustySort
                  accent
                  sortKey="kdr"
                  sortDir={params.sortDir}
                  sortBy={params.sortBy}
                  setParams={setParams}
                >
                  K/D
                </RustySort>
                <RustySort
                  accent
                  sortKey="playtime"
                  sortDir={params.sortDir}
                  sortBy={params.sortBy}
                  setParams={setParams}
                >
                  Hours
                </RustySort>
              </>
            ) : (
              <>
                <span>Player</span>
                {statTypeData?.map((data) => (
                  <RustySort
                    key={data.id}
                    small
                    accent
                    sortKey={data.id}
                    sortDir={params.sortDir}
                    sortBy={params.sortBy}
                    setParams={setParams}
                  >
                    {data.name}
                  </RustySort>
                ))}
              </>
            )}
          </LeadersHeader>
          {error ? (
            <RustyError error={error} retry={callApi} />
          ) : leaders && params ? (
            <>
              {leaders.currentUserStats && (
                <LeaderRow
                  currentUser={true}
                  statTypeData={statTypeData}
                  result={leaders.currentUserStats}
                  params={params}
                  totalColumns={totalColumns}
                  isClans={isClans}
                />
              )}
              <LeadersList
                statTypeData={statTypeData}
                leaders={leaders.results}
                params={params}
                totalColumns={totalColumns}
                isClans={isClans}
              />
              <RustyPagination
                params={params}
                setParams={setParams}
                totalResults={leaders.total}
              />
            </>
          ) : (
            <RustyLoading />
          )}
        </LeaderboardsInnerContainer>
      </Leaderboards>
    </LeaderboardsContainer>
  );
};

const LeadersList = ({
  leaders,
  statTypeData,
  params,
  totalColumns,
  isClans = false,
}) => (
  <LeadersContainer nogap totalColumns={totalColumns}>
    {leaders.length > 0 &&
      leaders.map((leader) => (
        <LeaderRow
          key={leader.user.steamId}
          result={leader}
          statTypeData={statTypeData}
          params={params}
          totalColumns={totalColumns}
          isClans={isClans}
        />
      ))}
  </LeadersContainer>
);

const LeaderRow = ({
  result,
  statTypeData,
  currentUser,
  params,
  totalColumns,
  isClans = false,
}) => {
  const isPVP = params?.statGroup === "pvp" || !params?.statGroup;

  return (
    <Leader
      isRanked={isPVP}
      totalColumns={totalColumns}
      currentUser={currentUser}
    >
      {isPVP ? (
        <>
          <Span subtext>#{result.rank || "?"}</Span>
          <RustyUser user={result.user} />
          <span>{result.kills}</span>
          <span>{result.deaths}</span>
          <span>{result.kdr?.toFixed(2)}</span>
          <span>{(result.playtime / 60 / 60).toFixed(2)}</span>
        </>
      ) : (
        <>
          <RustyUser user={result.user} notag />
          {statTypeData?.map((item) => {
            if (item.id === "user" || item.id === "rank") return;

            return <span key={item.id}>{result[item.id] ?? "N/A"}</span>;
          })}
        </>
      )}
    </Leader>
  );
};

const LeadersContainer = styled(RustyScroll)`
  ${(props) => props.theme.tablet} {
    overflow-x: auto;
    width: ${(props) => props.totalColumns * 10}rem;
  }
`;

const LeaderboardsContainer = styled.section`
  margin-left: 1rem;

  display: flex;
  flex-direction: column;

  width: 80.5%;
  height: 100%;

  ${(props) => props.theme.tablet} {
    width: 100%;
    margin-left: 0;
  }
`;

const LeaderboardsGroupSelector = styled.div`
  display: flex;
  margin-bottom: 1rem;
  position: absolute;

  box-shadow: ${(props) => props.theme.shadow};
  border-radius: 0.1rem;
  padding: 0.5rem;
  border: solid 0.3rem ${(props) => props.theme.colors.backgrounds[3]};
  background-color: ${(props) => props.theme.colors.backgrounds[2]};

  max-width: 100%;
  overflow-x: auto;

  ${(props) => props.theme.tablet} {
    position: relative;
    height: 200px;
    padding-top: 1.8rem;
    padding-bottom: 1.8rem;
  }
`;

const LeaderboardsInnerContainer = styled.div`
  box-shadow: ${(props) => props.theme.shadow};
  border-radius: 0.1rem;
  border: solid 0.5rem ${(props) => props.theme.colors.backgrounds[3]};
  background-color: ${(props) => props.theme.colors.backgrounds[2]};
  font-size: 2rem;
  padding: 0.5rem;
  height: 100%;

  display: flex;
  flex-direction: column;
  position: relative;
  pointer-events: auto;

  ${(props) => props.theme.tablet} {
    overflow-x: scroll;
    width: 100%;
  }
`;

const Leaderboards = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  padding-top: 5.5rem;
  pointer-events: none;

  ${(props) => props.theme.tablet} {
    padding-top: 0.2rem;
  }
`;

const LeadersHeader = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.isRanked ? "0.45fr 2fr" : "1.5fr")} ${(
      props
    ) => `repeat(${props.totalColumns || 4}, 0.5fr)`};
  border-bottom: solid 1px #cccccc;
  font-weight: bold;
  ${(props) => props.totalColumns > 9 && "font-size: 1.7rem"};
  ${(props) => props.totalColumns > 15 && "font-size: 1.5rem"};
  color: ${(props) => props.theme.colors.accent};
  padding: 1rem;
  padding-right: 1.5rem;
  ${(props) => props.theme.tablet} {
    font-size: 1.7rem;
    ${(props) =>
      props.isRanked &&
      `grid-template-columns: 0.25fr 0.4fr 0.55fr 0.55fr 0.5fr;`}
    ${(props) =>
      props.isRanked &&
      `
			& > *:nth-child(6) {
				display: none;
			}
		`}

		width: ${(props) => props.totalColumns * 10}rem;
  }
`;

const Leader = styled.li`
  display: grid;
  grid-template-columns: ${(props) => (props.isRanked ? "0.45fr 2fr" : "1.5fr")} ${(
      props
    ) => `repeat(${props.totalColumns || 4}, 0.5fr)`};
  border-bottom: solid 1px #cccccc;
  font-size: 80%;
  background-color: ${(props) => props.theme.colors.backgrounds[3]};
  padding: 0.75rem;
  ${(props) =>
    props.currentUser
      ? `
		padding-right: 1.5rem;
		background-color: ${props.theme.colors.accentDim};
	`
      : `
		&:nth-child(2n + 1) {
			background-color: ${props.theme.colors.backgrounds[5]};
		}
		&:hover {
			background-color: ${props.theme.colors.backgrounds[6]};
		}
	`}
  span {
    overflow: hidden;
    white-space: nowrap;
    display: flex;
    align-items: center;
  }
  svg {
    margin-left: 0.5rem;
    &:hover {
      color: ${(props) => props.theme.colors.accent};
    }
  }
  ${(props) => props.theme.tablet} {
    ${(props) =>
      props.isRanked
        ? `grid-template-columns: 0.25fr 0.4fr 0.55fr 0.55fr 0.5fr;`
        : `grid-template-columns: 0.5fr repeat(${
            props.totalColumns || 4
          }, 0.5fr)`}
    width: 100%;

    ${(props) =>
      props.currentUser &&
      `
			width: ${props.totalColumns * 10 - 2}rem;
		`}

    & > *:nth-child(1) {
      font-size: 1rem;
    }

    ${(props) =>
      props.isRanked &&
      `
			// Hide eye icon
			& > *:nth-child(3), *:nth-child(4) {
				& > *:nth-child(1) {
					& > *:nth-child(1) {
						display: none;
					}
				}
			}

			& > *:nth-child(6) {
				display: none;
			}

			// Hide name (only avatar)
			& > *:nth-child(2) {
				& > *:nth-child(2) {
					display: none;
				}
			}
		`}
  }
`;

export default Leaders;
