import React from "react";
import styled from "styled-components";

export default function Footer() {
  return (
    <FooterStyled>
      <a href="/terms-of-service">Terms Of Service</a>

      <a href="/privacy-policy">Privacy Policy</a>
    </FooterStyled>
  );
}

const FooterStyled = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: rgb(41, 24, 36);
  color: white;
  text-align: center;
  padding: 16px;
  display: flex;
  gap: 16px;
  justify-content: center;

  a:hover {
    text-decoration: underline;
  }

  z-index: 999 !important;
`;
