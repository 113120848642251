import api from './api.js';
import { faDiscord, faTwitch, faYoutube, faTwitter, faReddit, faSteam } from '@fortawesome/free-brands-svg-icons';

// TODO: Remove or purge this.

// Login in or log out the user depending on their authorization.
export const loginLogout = async (orgId, authorized, logoutUser, setLoading, search) => {
	if (authorized) {
		logoutUser();
	} else {
		if (setLoading) {
			setLoading("true");
		}
		localStorage.setItem('return', search || window.location.pathname + window.location.search);
		try {
			const { data } = await api.get('/service-auth/steam/redirect?orgId=' + orgId);
			window.location.href = data.redirectUrl;
		} catch (err) {
			console.error(err);
		}
	}
}

export const linkAccount = async (service, orgId) => {
	localStorage.setItem('return', window.location.pathname + window.location.search);
	try {
		const { data } = await api.get(`/service-auth/${service}/redirect?orgId=` + orgId);
		window.location.href = data.redirectUrl;
	} catch (err) {
		console.error(err);
	}
}

// Capitilzation, which I can't spell. Also I stole this.
export const Capitalize = (sentence) => {
	return sentence.split(' ').map(([firstLetter, ...restOfWord]) => firstLetter.toUpperCase() + restOfWord.join('')).join(' ');
}

// Filter the entity, and capitalize while you're at it.
export const filterEntity = (word) => {
	return Capitalize(word.replaceAll("_", " ").replace(".entity", "").replace(".weapon", ""))
}

export const serviceRenderHelpers = {
	STEAM: { 
		icon: faSteam,
		link: account => `https://steamcommunity.com/profiles/${account.id}`,
		avatar: account => account.avatar
	},
	DISCORD: {
		icon: faDiscord,
		link: account => `https://discordapp.com/users/${account.id}`,
		avatar: ({ id, avatar: hash }) => `https://cdn.discordapp.com/avatars/${id}/${hash}.png`
	},
	YOUTUBE: {
		icon: faYoutube,
		link: account => `https://youtube.com/channel/${account.id}`,
		avatar: account => account.avatar
	},
	TWITTER: {
		icon: faTwitter,
		link: account => `https://twitter.com/i/user/${account.id}`,
		avatar: account => account.avatar
	},
	REDDIT: {
		icon: faReddit,
		link: account => `https://reddit.com/user/${account.name}`,
		avatar: account => account.avatar
	},
	TWITCH: {
		icon: faTwitch,
		link: account => `https://twitch.tv/${account.name}`,
		avatar: account => account.avatar
	}
}

export const regex = { 
	steamId: id => /^\d{17}$/.test(id),
	ip: ip => /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi.test(ip)
}

export const fileSize = ( size ) => {
	return size < 1000 ?
		size + ' bytes' :
		size < 100000 ? 
		(size * 0.001).toFixed(1) + ' KB' :
		size < 100000000 ? 
		(size * 0.000001).toFixed(1) + ' MB' :
		(size * 0.000000001).toFixed(1) + ' GB'
}
