import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import Spinner from './Spinner.jsx';
  
const PrivateRoute = ({ authorized, me, redirect, children }) => {
if (!authorized) {
	if (redirect) {
		return <Navigate to={redirect} replace />;
	}
	return <PrivateWarning>Please log in to view this page.</PrivateWarning>;
}
if (!me) {
	return <Spinner />
}

return children ? children : <Outlet />;
};

const PrivateWarning = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100%;
	font-size: 3rem;
`

export default PrivateRoute;
  