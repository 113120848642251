import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

export const Avatar = styled.img` 
	height: 2.5rem;
	border-radius: 100%;
	${props => props.color && `box-shadow: 0 0 0 0.2rem ${props.color};`}
`;

export const Hamburger = styled(FontAwesomeIcon).attrs(() => ({
	icon: faBars
}))`
	display: none;
	margin-left: auto;
	font-size: 3rem;
	padding: 1rem;
	cursor: pointer;
	${props => props.theme.mobile} {
		display: initial;
	}
`;
