import React from "react";
import styled from "styled-components";

import Leaders from "./sections/Leaders.jsx";
import Heatmaps from "./sections/Heatmaps.jsx";

import useParams from "../../hooks/useParams.js";
import RustyFilter from "../../component/RustyFilter.jsx";

const Leaderboards = () => {
  const { params, setParams, setParamsState } = useParams({
    limit: 50,
    offset: 0,
    sinceWipe: null,
    since: "wipe",
    statGroup: "pvp",
    showIcons: "false",
    statType: "kills",
    type: "leaderboards",
  });
  const searches = [{ name: "id", placeholder: "Search by ID or username..." }];

  const switchesTypes = [
    {
      name: "type",
      title: "Type",
      options: [
        { name: "Players", value: "leaderboards" },
        { name: "Clans", value: "clans" },
        { name: "Heatmaps", value: "heatmaps" },
      ],
    },
  ];

  // TODO: Probably fetch available statTypes from the API..
  const switches = [
    ...switchesTypes,
    ...(params.type === "leaderboards"
      ? [
          {
            name: "sinceWipe",
            title: "Stats Range",
            options: [
              { name: "Lifetime", value: "false" },
              { name: "Since Wipe", value: null },
            ],
          },
        ]
      : params.type === "heatmaps"
      ? [
          {
            name: "since",
            title: "Heatmap Range",
            options: [
              { name: "Hour", value: "hour" },
              { name: "Day", value: "day" },
              { name: "Week", value: "week" },
              { name: "Wipe", value: "wipe" },
            ],
          },
          {
            name: "showIcons",
            title: "Show Icons",
            options: [
              { name: "No", value: "false" },
              { name: "Yes", value: "true" },
            ],
          },
          {
            name: "statType",
            title: "Heatmap Types",
            options: [
              { name: "Kills", value: "kills" },
              { name: "Gathered Wood", value: "gathered_wood" },
              { name: "Gathered Stones", value: "gathered_stones" },
              { name: "Gathered Sulfur", value: "gathered_sulfur.ore" },
              { name: "Gathered Metal", value: "gathered_metal.ore" },
              { name: "Gathered Cloth", value: "gathered_cloth" },
              { name: "Gathered HQM", value: "gathered_hq.metal.ore" },
            ],
            vertical: true,
          },
        ]
      : params.type === "clans" && [
          {
            name: "sinceWipe",
            title: "Stats Range",
            options: [
              { name: "Last wipe", value: "false" },
              { name: "Since Wipe", value: null },
            ],
          },
        ]),
  ];

  return (
    <LeaderWrapper>
      <RustyFilter
        alt
        title={"Leaderboards"}
        serverpicker
        defaultServer
        noCreative
        switches={switches}
        searches={searches}
        params={params}
        setParams={setParams}
        setParamsState={setParamsState}
      />

      {params.type === "leaderboards" && (
        <Leaders params={params} setParams={setParams} />
      )}
      {params.type === "heatmaps" && (
        <Heatmaps params={params} setParams={setParams} />
      )}
      {params.type === "clans" && (
        <Leaders params={params} setParams={setParams} isClans />
      )}
    </LeaderWrapper>
  );
};

const LeaderWrapper = styled.div`
  display: flex;
  height: 95%;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  margin-top: 2rem;
  margin-bottom: 2rem;

  ${(props) => props.theme.tablet} {
    flex-direction: column;
    overflow: auto;
    padding-top: 21rem;
  }
`;

export default Leaderboards;
