import React from "react";
import styled from "styled-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckSquare,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";

import { useStoreService } from "../store/index.jsx";
import RustySwitch from "./RustySwitch.jsx";
import RustyDatePicker from "./RustyDatePicker.jsx";
import { serviceRenderHelpers } from "../util/tools.js";
import moment from "moment";

// TODO: Option to clear filter on individual level and global level.

const RustyFilter = ({
  serverpicker,
  datepicker,
  servicepicker,
  switches,
  searches,
  params,
  setParams,
  setParamsState,
  setHas,
  setParamsSet,
  defaultServer,
  noCreative,
  title,
  alt,
}) => {
  const StoreService = useStoreService();
  const { servers } = StoreService.state;
  // Fetch servers if not already there.
  React.useEffect(() => {
    if (serverpicker && servers.length < 1) {
      StoreService.fetchServers();
    }
  }, [StoreService, servers]);

  // Set default selected ID to first non-creative server
  React.useEffect(() => {
    if (defaultServer && servers.length > 0 && !params.serverId) {
      setParamsState((params) => ({ ...params, serverId: servers[0].id }));
    }
  }, [servers, params]);

  // Submit form on enter press.
  const handleKeydown = (e, name, array) => {
    if (e.key === "Enter") {
      if (array) {
        setParamsSet(name, e.target.value);
        e.target.value = "";
      } else {
        setParams({ [name]: e.target.value });
      }
    }
  };

  return (
    <Filter $alt={alt}>
      <FilterHeader>{title || "Filters"}</FilterHeader>
      <Filters>
        {searches && (
          <FilterSearch>
            Search
            {searches.length > 0 &&
              searches.map((search) => (
                <FilterSearchArray key={search.name}>
                  <FilterInput
                    onKeyDown={(e) =>
                      handleKeydown(e, search.name, search.array)
                    }
                    defaultValue={!search.array ? params[search.name] : ""}
                    placeholder={search.placeholder}
                  />
                  {search.array && params[search.name] && (
                    <>
                      {Array.isArray(params[search.name]) ? (
                        <>
                          {params[search.name].length > 0 &&
                            params[search.name].map((value) => (
                              <FilterArrayItem key={value}>
                                {value}
                                <FontAwesomeIcon
                                  onClick={() =>
                                    setParamsSet(search.name, value)
                                  }
                                  icon={faTimesCircle}
                                />
                              </FilterArrayItem>
                            ))}
                        </>
                      ) : (
                        <FilterArrayItem>
                          {params[search.name]}
                          <FontAwesomeIcon
                            onClick={() =>
                              setParamsSet(search.name, params[search.name])
                            }
                            icon={faTimesCircle}
                          />
                        </FilterArrayItem>
                      )}
                    </>
                  )}
                </FilterSearchArray>
              ))}
          </FilterSearch>
        )}
        {switches &&
          switches.length > 0 &&
          switches.map((option) => (
            <FilterSearch key={option.title}>
              {option.title}
              <RustySwitch
                name={option.name}
                options={option.options}
                params={params}
                setParams={setParams}
                vertical={option.vertical}
              />
            </FilterSearch>
          ))}
        {servicepicker && (
          <FilterServices>
            Services
            <div>
              {Object.keys(serviceRenderHelpers).map((service) => {
                let paramKey = (follower) =>
                  `${service.toLowerCase()}${
                    follower ? "Follower" : "CreatedBefore"
                  }`;
                let linked = params[paramKey()],
                  follower = params[paramKey(true)];
                return (
                  <FilterService
                    title={`${
                      follower
                        ? `Remove ${service.toLowerCase()} filter`
                        : `Show ${service.toLowerCase()} ${
                            linked ? "followers" : "linked accounts"
                          }`
                    }`}
                    key={service}
                    $active={linked}
                    $follower={follower}
                    icon={serviceRenderHelpers[service].icon}
                    onClick={() =>
                      setParams({
                        [paramKey()]: linked
                          ? null
                          : follower
                          ? null
                          : moment().format(),
                        [paramKey(true)]: linked ? true : null,
                      })
                    }
                  />
                );
              })}
            </div>
          </FilterServices>
        )}
        {datepicker && (
          <FilterDates>
            Time
            {datepicker.length > 0 &&
              datepicker.map((dateFilter) => (
                <RustyDatePicker
                  key={dateFilter.title}
                  dateFilter={dateFilter}
                  params={params}
                  setParams={setParams}
                  alt={alt}
                />
              ))}
          </FilterDates>
        )}
        {serverpicker && (
          <FilterSearch overflow>
            Servers
            {!defaultServer && (
              <FiltersInline>
                <FilterServer
                  onClick={() =>
                    setParams({ serverId: servers.map((server) => server.id) })
                  }
                >
                  All
                </FilterServer>
                <FilterServer onClick={() => setParams({ serverId: null })}>
                  None
                </FilterServer>
              </FiltersInline>
            )}
            <FilterSearch noMargin overflow>
              {servers &&
                servers.length > 0 &&
                servers.map(
                  (server) =>
                    (!noCreative || !server.creative) && (
                      <FilterServer
                        key={server.id}
                        selected={
                          defaultServer
                            ? server.id === params.serverId
                            : setHas("serverId", server.id) ||
                              server.id === params.serverId
                        }
                        onClick={() => setParams({ serverId: server.id })}
                      >
                        {server.name}
                        <FontAwesomeIcon
                          icon={faCheckSquare}
                          onClick={(e) => {
                            e.stopPropagation();
                            !defaultServer &&
                              setParamsSet("serverId", server.id);
                          }}
                        />
                      </FilterServer>
                    )
                )}
            </FilterSearch>
          </FilterSearch>
        )}
      </Filters>
    </Filter>
  );
};

const Filter = styled.section`
  display: flex;
  flex-direction: column;
  width: 20%;
  border: solid 2px ${(props) => props.theme.colors.backgrounds[1]};
  background-color: ${(props) => props.theme.colors.backgrounds[3]};
  ${(props) =>
    props.$alt &&
    `
		height: 100%;
		width: 15%;
		border: solid 5px ${props.theme.colors.backgrounds[3]};
		box-shadow: 0rem 0rem 1rem .5rem rgb(0, 0, 0, 0.2);
		background-color: ${props.theme.colors.backgrounds[2]};
		${FilterHeader} {
			background-color: ${props.theme.colors.backgrounds[2]};
			font-weight: bold;
			text-align: center;
		}
		${FilterDates} {
			& > div {
				background-color: ${props.theme.colors.backgrounds[3]};
			}
		}
		${FilterServer} {
			background-color: ${props.theme.colors.backgrounds[3]};
		}
		${FilterArrayItem} {
			background-color: ${props.theme.colors.backgrounds[3]};
		}
		${FilterService} {
			background-color: ${props.theme.colors.backgrounds[3]};
		}
		padding: .5rem;
	`}
  ${(props) => props.theme.tablet} {
    width: 95%;
    margin-bottom: 2rem;
    min-height: 40rem;
  }
`;

const FilterHeader = styled.div`
  background-color: ${(props) => props.theme.colors.backgrounds[1]};
  padding: 0.375rem;
  font-size: 3rem;
`;

const Filters = styled.div`
  overflow-y: auto;
  ${(props) => props.theme.scrollbar}
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;

const FilterSearch = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => !props.noMargin && "margin: 0.375rem;"}
  gap: 0.75rem;
  ${(props) => props.overflow && "overflow: auto;"}
  ${(props) => props.theme.scrollbar}
`;

const FilterServices = styled(FilterSearch)`
  & > div {
    display: flex;
    justify-content: space-between;
  }
`;

const FilterService = styled(FontAwesomeIcon)`
  ${(props) =>
    props.$active
      ? `color: ${props.theme.colors.text};`
      : props.$follower
      ? `color: ${props.theme.colors.accent};`
      : `color: ${props.theme.colors.grayedText};`}
  cursor: pointer;
  padding: 1rem;
  background-color: ${(props) => props.theme.colors.backgrounds[2]};
`;

const FilterSearchArray = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.375rem;
`;

const FilterArrayItem = styled.div`
  background-color: ${(props) => props.theme.colors.backgrounds[2]};
  padding: 0.5rem;
  display: flex;
  svg {
    margin-left: auto;
    color: ${(props) => props.theme.colors.accent};
    cursor: pointer;
  }
`;

const FilterInput = styled.input`
  background-color: ${(props) => props.theme.colors.backgrounds[1]};
  padding: 0.5rem;
  font-size: 1.75rem;
`;

const FilterDates = styled(FilterSearch)``;

const FiltersInline = styled.div`
  display: flex;
  gap: 0.375rem;
  div {
    flex: 1;
    justify-content: center;
  }
`;

const FilterServer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.backgrounds[2]};
  cursor: pointer;
  padding: 0.5rem;
  font-size: 1.75rem;
  svg {
    margin-left: auto;
    color: ${(props) =>
      props.selected
        ? props.theme.colors.accent
        : props.theme.colors.grayedText};
    pointer-events: none;
  }
`;

export default RustyFilter;
